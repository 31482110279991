export default class Resource {
  status!: number
  data: any
  exception: any

  static ERROR = -1
  static LOADING = 0
  static SUCCESS = 1

  constructor(s: number, d: any, e: any) {
    this.status = s
    this.data = d
    this.exception = e
  }

  static loading() {
    return new Resource(Resource.LOADING, null, null)
  }

  static error(exception: any) {
    return new Resource(Resource.ERROR, null, exception)
  }

  static success(data: any) {
    return new Resource(Resource.SUCCESS, data, null)
  }
}