import {
  collection,
  query,
  getDocs,
  orderBy,
  startAfter,
  limit,
  where,
} from 'firebase/firestore'
import { db } from '@/main'
import Resource from '@/data/tools/Resource'
export default class DrawDao {
  last: any

  async getDraws(callback: (resource: Resource) => any) {
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, 'drawings'),
        where('isDeleted', '==', false),
        orderBy('created', 'desc')
      )
      const snap = await getDocs(q)
      this.last = snap.docs[snap.docs.length - 1]
      callback(Resource.success(snap.docs.map((doc) => doc.data())))
    } catch (error) {
      callback(Resource.error(error))
    }
  }
  async getNextDraws(callback: (resource: Resource) => any) {
    callback(Resource.loading())
    try {
      if (this.last !== null && this.last !== undefined) {
        const q = query(
          collection(db, 'drawings'),
          where('isDeleted', '==', false),
          orderBy('created', 'asc'),
          startAfter(this.last),
          limit(15)
        )
        const snap = await getDocs(q)
        this.last = snap.docs[snap.docs.length - 1]
        callback(Resource.success(snap.docs.map((doc) => doc.data())))
      } else {
        callback(Resource.success('No more!'))
      }
    } catch (error) {
      callback(Resource.error(error))
    }
  }
}
